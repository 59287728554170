import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik } from 'formik'

import User from '../../models/UserStore'
import ApiUser from '../../shared/login/ApiUser'
import avatarDefault from '../../assests/img/avatar-default.png'
import Translate from './../../utils/Translate'
import AlertModal from '../alerts/AlertModal'
import CheckErrors from '../alerts/CheckErrors'
import DeleteAccount from './DeleteAccount'
import Catalogs from '../HocCatalogs/HocCatalogs'
import { validateForm, showImage, getErrors } from '../utils'
import mixpanel from 'mixpanel-browser';

class UpdateProfile extends Component {
  state = {
    avatar: 'https://storage.googleapis.com/seenapse-assets/images/avatar-default.png',
    showAlertErrors: false,
    showRegisterSuccess: false,
    errors: []
  }

  componentDidMount = async () => {
    const { userStore } = this.props
    userStore.getProfile()
    userStore.getUserProfile()
  }

  closeModalErrors = () => {
    this.setState({ showAlertErrors: false })
  }

  closeModalRegisterSuccess = () => {
    const userId = User.getUid()
    const { history } = this.props
    history.push(`/account/${userId}/seenapses`)
  }

  removeImage = () => {
    this.setState({
      avatar: 'https://storage.googleapis.com/seenapse-assets/images/avatar-default.png'
    })
  }

  handleUploadImage = async (event, setFieldValue) => {
    const result = await showImage(event)
    if (!result.imageSelected) {
      this.setState({
        errors: [`${Translate.get('errImageDimension', 'Please add an image at least 200x200 pixels.')}`],
        showAlertErrors: true
      })
    } else {
      setFieldValue('avatar', result.imageSelected)
    }
  }

  handleUpdateProfile = async values => {
    const userId = User.getUid()
    const params = {
      email: values.email,
      bio: values.bio,
      name: values.name,
      profession_id: Number(values.profession_id),
      country_id: Number(values.country_id),
      password_confirmation: values.password_confirmation,
      password: values.password,
      avatar: values.avatar
    }

    const notificationParams = {
      new_followed_seenapses: values.new_followed_seenapses,
      new_followers: values.new_followers,
      seenapses_children: values.seenapses_children,
      faves: values.faves,
      brainstorming_requests: values.brainstorming_requests
    }
    const errors = validateForm(params)
    const dataToSend = {
      user: { ...params },
      notifications: { ...notificationParams }
    }
    if (errors.length > 0) {
      this.setState({ errors: errors, showAlertErrors: true })
    }
    try {
      await ApiUser.update(userId, dataToSend)
      this.setState({ showRegisterSuccess: true })
    } catch (error) {
      const { labelError, showAlertError } = getErrors(error)
      this.setState({
        errors: [`Error: ${labelError}`],
        showAlertErrors: showAlertError
      })
    }
  }

  render() {
    const {
      userStore: { userProfile, currentUser },
      history
    } = this.props

    const { avatar, showAlertErrors, showRegisterSuccess, errors } = this.state
    return (
      <div>
        <CheckErrors
          open={showAlertErrors}
          errors={errors}
          onOk={this.closeModalErrors}
        />
        <AlertModal
          message={Translate.get(
            'informationWasUpdated',
            'Your preferences were updated'
          )}
          open={showRegisterSuccess}
          onOk={this.closeModalRegisterSuccess}
        />
        <div className="title-preferences">
          {Translate.get(
            'editYourAccount',
            'Edit your account'
          )}
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: userProfile.email || '',
            bio: userProfile.bio || '',
            name: userProfile.name || '',
            profession_id: userProfile.profession_id || '',
            country_id: userProfile.country_id || '',
            password: '',
            password_confirmation: '',
            avatar: userProfile.avatar
          }}
          onSubmit={this.handleUpdateProfile}
        >
          {({ values, handleSubmit, handleChange, setFieldValue }) => (
            <div id='profile-preferences' className='login-form' style={{margin: '0 auto'}}>
              <form className='container-profile' onSubmit={handleSubmit}>
                <fieldset
                  className={`col12 mb-4 ${
                    userProfile.provider !== 'email' ? 'profile-avatar' : ''
                  }`}
                >
                  <div className='profile-pic'>
                    <div className='pic-wrapper'>
                      <div className='pic'>
                        <img
                          id='thumbnil'
                          src={
                            values.avatar === 'https://storage.googleapis.com/seenapse-assets/images/avatar-default.png'
                              ? avatarDefault
                              : values.avatar
                          }
                          width='100'
                          height='100'
                        />
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='options'>
                          <label className='file'>
                            <input
                              type='file'
                              onChange={(e) => {this.handleUploadImage(e, setFieldValue)}}
                              accept="image/png, image/jpeg"
                              name='Upload photo'
                              className='input-file'
                              aria-label='File browser'
                            />
                            <span className='file-title'>
                              {Translate.get('uploadPhoto', 'Upload new image')}
                            </span>
                          </label>
                        </div>
                        {avatar !== 'https://storage.googleapis.com/seenapse-assets/images/avatar-default.png' && (
                          <button
                            id='register'
                            className='theme-yellow remove-image'
                            onClick={this.removeImage}
                          >
                            {Translate.get(
                              'removeCurrentImage',
                              'Remove current image'
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className='row'>
                  <fieldset className='col12 col-md-6 align-self-end'>
                    <div className='mb-3'>
                      <label className='required text-left'>
                        {Translate.get('yourEmail', 'Your Email')}
                      </label>
                      <input
                        id='email'
                        type='email'
                        onChange={handleChange}
                        value={values.email}
                        autoComplete='new-email'
                        disabled={userProfile.provider !== 'email'}
                        required
                      />
                    </div>
                    <div className='mb-3'>
                      <label className='required text-left'>
                        {Translate.get('name', 'Name')}
                      </label>
                      <input
                        id='name'
                        type='text'
                        onChange={handleChange}
                        value={values.name}
                        required
                      />
                    </div>
                    {userProfile.provider === 'email' && (
                      <Fragment>
                        <div className='mb-3'>
                          <label className='required'>
                            {Translate.get('password', 'Password')}
                          </label>
                          <input
                            id='password'
                            type='password'
                            onChange={handleChange}
                            value={values.password}
                            autoComplete='new-password'
                            required
                          />
                        </div>
                        <div className='mb-3'>
                          <label className='required'>
                            {Translate.get(
                              'passwordConfirm',
                              'Confirm your Password'
                            )}
                          </label>
                          <input
                            id='password_confirmation'
                            type='password'
                            onChange={handleChange}
                            value={values.password_confirmation}
                            required
                          />
                        </div>
                      </Fragment>
                    )}
                  </fieldset>

                  <fieldset className='col12 col-md-6'>
                    <div className='selectors'>
                      <div className='mb-3'>
                        <label className='required text-left'>
                          {Translate.get('yourProfession', 'Your profession')}
                        </label>
                        <div className='select-container'>
                          <Catalogs>
                            {({ professions }) => (
                              <select
                                id='profession_id'
                                name='profession_id'
                                value={values.profession_id}
                                onChange={handleChange}
                              >
                                <option value='' default selected disabled>
                                  {Translate.get(
                                    'yourProfession',
                                    'Your profession'
                                  )}
                                </option>
                                {professions.map(({ id, label }) => (
                                  <option key={id} value={id}>
                                    {label}
                                  </option>
                                ))}
                              </select>
                            )}
                          </Catalogs>
                          <div className='arrow arrow-profile'></div>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label className='required text-left'>
                          {Translate.get(
                            'countryLived',
                            'Country where you have lived most of your life'
                          )}
                        </label>
                        <div className='select-container'>
                          <Catalogs>
                            {({ countries }) => (
                              <select
                                id='country_id'
                                name='country_id'
                                value={values.country_id}
                                onChange={handleChange}
                              >
                                <option className='s' value='' default selected>
                                  {Translate.get('country', 'country')}
                                </option>
                                {countries.map(({ id, label }) => (
                                  <option key={id} value={id}>
                                    {label}
                                  </option>
                                ))}
                              </select>
                            )}
                          </Catalogs>
                          <div className='arrow arrow-profile'></div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <label className='text-left'>Bio</label>
                      <textarea
                        id='bio'
                        name='bio'
                        onChange={handleChange}
                        value={values.bio}
                        className='textarea-update'
                      />
                    </div>
                    <div id='injection_site'></div>
                  </fieldset>
                </div>
                <div className='d-flex justify-content-center my-5'>
                  <button
                    type='submit'
                    id='register'
                    className='theme-yellow mr-5'
                    onClick={this.onSumit}
                  >
                    {Translate.get('savePreferences', 'Save Preferences')}
                  </button>
                  <button
                    id='cancel'
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    {Translate.get('cancel', 'Cancel')}
                  </button>
                </div>
              </form>
              {currentUser.has_subscription === 1 &&
                <div className='d-flex justify-content-center items-center mb-4'>
                  <a
                    onClick={(e)=> { mixpanel.track('Manage subscription'); return false;}}
                    href="https://pay.seenapse.ai/p/login/aEU3ew3cNgcPe2ceUU"
                    target='_blank'
                    rel='noopener noreferrer'
                    className="account-link-button"
                  >
                    {Translate.get('manageSubscription', 'Manage your subscription')}
                  </a>
                </div>
              }
              <DeleteAccount />
            </div>
          )}
        </Formik>
      </div>
    )
  }
}

export default inject('modalStore', 'userStore')(observer(UpdateProfile))
